import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Container,
	Image,
	Flex,
	Heading,
	Button,
	HStack,
	IconButton,
} from '@chakra-ui/react';
import { NONAME } from 'dns';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IGalleryItem } from '../../types/gallery';

import me from '../img/me.png';
import me_t from '../img/me_transparent.png';
import AnimatedText from './AnimatedText';
import GalleryItem from './GalleryItem';

interface Props {
	items?: IGalleryItem[];
}

const Gallery = (props: Props) => {
	const { items } = props;

	const containerRef = useRef<HTMLDivElement>(null);

	const prevItem = useRef<HTMLDivElement>(null);
	const nextItem = useRef<HTMLDivElement>(null);

	const [activeItem, setActiveItem] = useState<IGalleryItem>({
		id: 'no layout id',
		backImage: me,
		topImage: me_t,
		color: '#dbe8f5',
		title: 'Placeholder',
		slogan: 'Placeholder',
		button: { link: '/portfolio', text: 'view project' },
	});

	const scrollTo = (el: any) => {
		let scrollPos = el.offsetLeft;

		if (el.current) {
			el.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'center',
			});
		} else {
			el.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'center',
			});
		}

		if (containerRef.current) {
			//console.log('current:' + containerRef.current.scrollLeft);
			//containerRef.current.scrollLeft = scrollPos + window.innerWidth / 4;
		}
	};

	useEffect(() => {
		items && setActiveItem(items[0]);
	}, []);

	useEffect(() => {}, [activeItem]);

	//const { color, backImage, topImage, slogan, title, button, desc } = props;
	return (
		<Flex
			flexDirection="column"
			minH="80px"
			justifyContent={'flex-end'}
			alignItems="center"
			gap={10}
		>
			<Box
				position="absolute"
				w="100vw"
				h="175%"
				bg={activeItem.color}
				top={'-125%'}
				left={0}
				transition="background-color 0.6s ease-in"
			></Box>
			<Flex
				flexDirection="column"
				w="100vw"
				justifyContent="center"
				alignItems={'center'}
				position="relative"
			>
				<HStack
					ref={containerRef}
					maxW="100vw"
					position="relative"
					overflowX="auto"
					spacing={{ base: '10vw', lg: '15vw' }}
					scrollSnapType="x mandatory"
					px={{ base: '40vw' }}
					py={{ base: '5vh' }}
					sx={{
						scrollbarWidth: 'none',
						'::-webkit-scrollbar': {
							display: 'none',
							opacity: 0,
						},
						'::-webkit-scrollbar-track': {
							display: 'none',
							opacity: 0,
						},
						'::-webkit-scrollbar-thumb': {
							display: 'none',
							opacity: 0,
						},
					}}
				>
					{items?.map((item, i) => (
						<Box
							onClick={(e: any) => {
								scrollTo(e.currentTarget);
								e.preventDefault();
							}}
							key={`gallery item of ${item.title}`}
							style={{
								pointerEvents: activeItem.title != item.title ? 'all' : 'none',
							}}
							ref={
								items[i - 1] && items[i - 1].id == activeItem.id
									? nextItem
									: items[i + 1] && items[i + 1].id == activeItem.id
									? prevItem
									: undefined
							}
						>
							<Link
								to={item.button?.link || ''}
								style={{
									pointerEvents:
										activeItem.title == item.title ? 'all' : 'none',
								}}
							>
								<GalleryItem
									layoutId={item.id}
									active={activeItem.title == item.title ? true : false}
									item={item}
									nav={
										items[i - 1] && items[i - 1].id == activeItem.id
											? 'next'
											: items[i + 1] && items[i + 1].id == activeItem.id
											? 'prev'
											: ''
									}
									handleInView={setActiveItem}
								></GalleryItem>
							</Link>
						</Box>
					))}
				</HStack>
				<Flex>
					<IconButton
						variant="ghost"
						aria-label="previous item"
						onClick={() => prevItem && scrollTo(prevItem)}
						icon={<ChevronLeftIcon />}
						size="lg"
						isDisabled={items && activeItem.id == items[0].id}
					/>
					<IconButton
						icon={<ChevronRightIcon />}
						variant="ghost"
						aria-label="next item"
						onClick={() => nextItem && scrollTo(nextItem)}
						size="lg"
						isDisabled={items && activeItem.id == items[items.length - 1].id}
					/>
				</Flex>
				{/* <Flex
					position="absolute"
					justifyContent="center"
					alignItems="center"
					pointerEvents={'none'}
					w="100vw"
					zIndex={5}
				>
					<AnimatePresence mode="wait">
						<AnimatedText
							key={activeItem.slogan}
							text={activeItem.slogan}
							fontColor={activeItem.fontColor}
						></AnimatedText>
					</AnimatePresence>
				</Flex> */}
			</Flex>
		</Flex>
	);
};

export default Gallery;
