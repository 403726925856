import { defineStyleConfig } from '@chakra-ui/react';
import { m } from 'framer-motion';

export const Container = defineStyleConfig({});

export const Tabs = defineStyleConfig({
	variants: {
		'solid-rounded': {
			tab: {
				borderRadius: '5px',
			},
		},
	},
});
export const Button = defineStyleConfig({
	baseStyle: {
		textTransform: 'uppercase',
		letterSpacing: 2,
		borderRadius: '5px',
		color: 'white',
	},
});

export const Heading = defineStyleConfig({
	baseStyle: {
		textTransform: 'uppercase',
		fontWeight: 'regular',
		color: '#1a1a1a',
	},
	sizes: {
		'4xl': {
			fontSize: ['5xl', null, '7xl', '8xl', '8xl'],
			lineHeight: 1.2,
		},
		xs: { fontFamily: 'Manrope', fontWeight: 600, letterSpacing: 2 },
	},
});
