import {
	AspectRatio,
	Box,
	Button,
	Container,
	Flex,
	Heading,
	Text,
	Image,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useContentful } from '../../hooks/useContentful';
import { Section } from '../../shared/components/Section';
import { ProjectEntry } from '../../types/portfolio';

import { Link, useParams } from 'react-router-dom';
import GalleryItem from '../../shared/components/GalleryItem';
import { projectToGalleryItem } from '../../shared/helper/gallery';
import { IGalleryItem } from '../../types/gallery';
import { motion, MotionConfig } from 'framer-motion';
import Slideshow from '../../shared/components/Slideshow';

const Project = () => {
	const { id } = useParams();
	const [project, setProject] = useState<ProjectEntry>();

	const { get } = useContentful();

	const loadData = async () => {
		const { data, loaded, error } = await get<ProjectEntry[]>({
			content_type: 'project',
			'sys.id': id,
		});
		setProject(data[0]);
	};

	useEffect(() => {
		loadData();
		console.log(project?.fields.projectSections);
	}, []);

	return (
		<motion.div layoutId={`layoutId:${id}`}>
			{project && (
				<motion.div
					animate={{ opacity: 1, y: 0 }}
					initial={{ opacity: 0, y: -10 }}
					transition={{ delay: 1 }}
				>
					<Container maxW="4xl" my="10vh">
						<Flex
							flexDirection="column"
							justifyContent={'center'}
							alignItems="center"
							gap={10}
						>
							<Heading size="2xl" textAlign="center">
								{project.fields.title}
							</Heading>

							<Text
								w={{ base: '100%', md: '70%', lg: '50%' }}
								textAlign={{ base: 'left', md: 'center' }}
							>
								{project.fields.description}
							</Text>
							{project.fields.team && (
								<Text
									textAlign="center"
									w={{ base: '100%', md: '70%', lg: '50%' }}
								>
									{`${
										project.fields.semester
											? `${project.fields.semester}${
													project.fields.semester == 1
														? 'st'
														: project.fields.semester == 2
														? 'nd'
														: project.fields.semester == 3
														? 'rd'
														: 'th'
											  } semester `
											: ''
									}project by `}
									{project.fields.team.map((member, i) => (
										<span key={`team member: ${member}`}>
											{member}
											{i < project.fields.team.length - 1 ? ', ' : ''}
										</span>
									))}
									{` & Matthias Dietrich`}
								</Text>
							)}
						</Flex>
					</Container>

					{project.fields.projectSections.map((section) => (
						<Section
							key={`section with title ${section.fields.title}`}
							name={section.fields.title}
						>
							<Container maxW="4xl" my={10}>
								<Flex
									flexDirection="column"
									alignItems={'center'}
									justifyContent="center"
								>
									<Text
										sx={{
											columnCount: [1, 1, 2],
											columnGap: [10, 10, 10, , 20, 70],
										}}
									>
										{section.fields.text}
									</Text>
								</Flex>
							</Container>
							{section.fields.image && section.fields.image.length > 0 && (
								<Slideshow images={section.fields.image} />
							)}
							{section.fields.video && (
								<Flex w="100%" marginBottom={10}>
									<Container maxW="4xl">
										<AspectRatio w="100%">
											<iframe
												src={`${section.fields.video}`}
												frameBorder="0"
												allowFullScreen
												title="Embedded vimeo video"
											/>
										</AspectRatio>
									</Container>
								</Flex>
							)}
						</Section>
					))}
				</motion.div>
			)}
		</motion.div>
	);
};

export default Project;
