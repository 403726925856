import { IGalleryItem } from '../../types/gallery';
import { ProjectEntry } from '../../types/portfolio';

export const projectToGalleryItem = (project: ProjectEntry) => {
	let galleryItem: IGalleryItem = {
		id: project.sys.id,
		title: project.fields.title,
		slogan: project.fields.slogan,
		backImage: project.fields.thumbnail.fields.file.url,
		topImage: project.fields.thumbnailTop
			? project.fields.thumbnailTop.fields.file.url
			: undefined,
		color: project.fields.color,
		button: { link: `/project/${project.sys.id}`, text: 'View project' },
		fontColor: project.fields.fontColor,
		semester: project.fields.semester,
	};

	return galleryItem;
};
