import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { NavBar } from './shared/components/NavBar';
import { AppRoutes } from './types/appRoutes';
import Footer from './shared/components/Footer';
import { Box } from '@chakra-ui/react';

export const App: React.FC = () => {
	return (
		<>
			<Router>
				<Box minH="85vh" maxW={'100vw'}>
					<NavBar></NavBar>

					<AnimatedRoutes />
				</Box>
			</Router>
		</>
	);
};

//element={<ProtectedRoute children={<Admin />} />}

const animVariants: Variants = {
	hidden: {
		y: -100,
		opacity: 0,
		transition: { ease: 'anticipate', duration: 1 },
	},
	visible: {
		y: 0,
		opacity: 1,
		transition: { ease: 'anticipate', duration: 2 },
	},
};

const projectAnim: Variants = {
	hidden: {
		y: 200,
		opacity: 0,
		transition: { ease: 'anticipate', duration: 2 },
	},
	visible: {
		y: 0,
		opacity: 1,
		transition: { ease: 'anticipate', duration: 0.6 },
	},
};

const AnimatedRoutes = () => {
	let location = useLocation();

	return (
		<AnimatePresence mode="wait">
			<Routes location={location} key={location.pathname}>
				{AppRoutes.map((route) => (
					<Route
						key={`route of ${location.pathname}`}
						path={route.path}
						element={
							<motion.div
								initial="hidden"
								animate="visible"
								exit="hidden"
								variants={route.name == 'Project' ? projectAnim : animVariants}
							>
								<route.component />
							</motion.div>
						}
					/>
				))}
			</Routes>
		</AnimatePresence>
	);
};
