import { useToast } from '@chakra-ui/react';
import axios, { AxiosError, isAxiosError } from 'axios';
import React from 'react';
import { authService } from '../services';
import { space, accessToken, backendUri } from '../shared/helper/env';

import * as contentful from 'contentful';

export const client = contentful.createClient({
	space: space,
	accessToken: accessToken,
});

export const useContentful = () => {
	const toast = useToast();

	async function get<T>(
		options: any
	): Promise<{
		data: T;
		loaded: boolean;
		error: AxiosError | null;
	}> {
		let data: any;
		let loaded: boolean = false;
		let error: any;

		try {
			const res = await client.getEntries({
				...options,
			});
			data = [...res.items];
		} catch (err) {
		} finally {
			loaded = true;
		}

		return { data, error, loaded };
	}

	function defaultErrorToast(error: AxiosError) {
		const { message } = error;
		toast({
			title: 'Error 🚧',
			description: `${message}`,
			status: 'error',
			duration: 3000,
			isClosable: true,
		});
	}

	return { get };
};
