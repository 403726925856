import { Container, Flex, Heading, Box } from '@chakra-ui/react';

interface Props {
	name: string;
	color?: string;
	children: string | JSX.Element | JSX.Element[];
}

export const Section = (props: Props) => {
	const { name, color, children } = props;

	let defaultColor = 'blackAlpha.300';

	return (
		<Flex flexDirection="column" marginBottom={10} width="100%">
			<Container maxW="4xl">
				<Heading size={'lg'} lineHeight={'75%'}>
					{name}
				</Heading>
			</Container>
			<Box
				//bgGradient={`linear(to-b, ${color || defaultColor}, transparent)`}
				paddingTop={0}
				paddingBottom={{ base: 10 }}
				transition={'height 0.3s ease-in-out'}
			>
				{children}
			</Box>
		</Flex>
	);
};
