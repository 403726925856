import { extendTheme } from '@chakra-ui/react';

import { Container, Tabs, Heading, Button } from './components';

import './../fonts/fonts.css';

const MDUX = extendTheme({
	borderRadius: '5px',
	colors: {
		teal: {
			50: '#91D1D5',
			100: '#86CCD1',
			200: '#7AC7CC',
		},
		orange: {
			200: '#FAD0A2',
		},
	},
	fonts: {
		heading: `Gigantic FS, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
		body: `SF Pro, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
		mono: `Manrope, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
	},

	components: {
		Container,
		Tabs,
		Heading,
		Button,
	},
});

export default MDUX;
