import React from 'react';
import { motion } from 'framer-motion';
import { Flex, Heading } from '@chakra-ui/react';

interface Props {
	children: JSX.Element | JSX.Element[];
}

interface ACProps {
	text: string;
	fontColor?: string;
}

const AnimatedText = (props: ACProps) => {
	const { text, fontColor } = props;
	let counter = 0;
	let wordcounter = 0;

	// Framer Motion variant object, for controlling animation
	const anims = {
		hidden: {
			y: '200%',
			opacity: 0,
			transition: {
				ease: [0.455, 0.03, 0.515, 0.955],
				duration: 0.25,
				delay: 0.015 * counter,
			},
		},
		visible: {
			opacity: 1,
			y: 0,
		},
		out: {
			y: 0,
			opacity: 0,
			transition: {
				ease: [0.455, 0.03, 0.515, 0.955],
				duration: 0.25,
				delay: 0,
			},
		},
	};

	const wrapper = {
		visible: {
			transition: {
				staggerChildren: 0.025,
			},
		},
		hidden: {
			transition: {
				staggerChildren: 0.025,
			},
		},
	};

	const splitWords = text.split(' ');

	const words: string[][] = [];

	splitWords.map((item) => {
		words.push(item.split(''));
	});

	words.forEach((item) => {});

	/* words.map((word, i) => {
		return i < words.length - 2 ? word.push('\u00A0') : '';
	}); */

	return (
		<>
			{words && (
				<motion.div animate="visible" exit="hidden" variants={wrapper}>
					<Flex flexDirection="column">
						{words.map((word, i) => {
							wordcounter = 0;
							return (
								<Flex
									alignItems="center"
									justifyContent="center"
									key={`letter ${i} of ${word}`}
								>
									{word.flat().map((letter, j) => {
										counter++;
										wordcounter++;
										return (
											<Flex
												flexDirection="column"
												style={{
													overflow: 'hidden',
													display: 'inline-block',
												}}
												key={`letter with i${j}`}
												gap={'10vw'}
											>
												<motion.span
													style={{ display: 'inline-block' }}
													initial="hidden"
													animate="visible"
													exit="out"
													variants={anims}
													transition={{
														ease: [0.455, 0.03, 0.515, 0.955],
														duration: 0.75,
														delay: 0.015 * counter,
													}}
													key={`motion.span with i${i}`}
												>
													<Heading
														key={`letter - ${letter}`}
														size={{ base: '4xl' }}
														textAlign="center"
														mx={{ base: 1, xl: 2 }}
														my={{ base: 1 }}
														color={fontColor}
														//marginTop={`${wordcounter * 30}px`}
													>
														{`${letter}`}
													</Heading>
												</motion.span>
											</Flex>
										);
									})}
								</Flex>
							);
						})}
					</Flex>
				</motion.div>
			)}
		</>
	);
};

export default AnimatedText;
