import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Container,
	Flex,
	Heading,
	Box,
	Button,
	Image,
	AspectRatio,
	IconButton,
	Icon,
} from '@chakra-ui/react';
import { AnimatePresence, LayoutGroup, motion, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isMetaProperty } from 'typescript';
import { IGalleryItem } from '../../types/gallery';

import blackDot from '../../shared/img/black_dot.png';
import AnimatedText from './AnimatedText';

interface Props {
	item?: IGalleryItem;
	handleInView?: (item: IGalleryItem) => void;
	active?: boolean;
	layoutId?: string;
	open?: boolean;
	nav?: string | undefined;
}

const defaultItem: IGalleryItem = {
	id: 'no id',
	color: '#dbe8f5',
	title: 'Placeholder',
	slogan: 'Placeholder',
	button: { link: '/portfolio', text: 'view project' },
};

const GalleryItem = (props: Props) => {
	let { item, handleInView, active, layoutId, open, nav } = props;

	const { id } = useParams();
	item = item || defaultItem;
	layoutId = layoutId || id;

	const ref = useRef(null);
	let isInView = useInView(ref, { margin: '-50% -50%' });

	useEffect(() => {
		item && handleInView && isInView && handleInView(item);
	}, [isInView]);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			ref={ref}
			key={`gallery item with id:${item.id}`}
		>
			<Flex
				scrollSnapAlign="center"
				justifyContent="center"
				alignItems="center"
				transform={active ? 'scale(1.05)' : 'scale(1)'}
				transition={'transform 0.3s ease-in-out'}
			>
				<Flex
					position="absolute"
					justifyContent="center"
					alignItems="center"
					pointerEvents={'none'}
					w="100vw"
					zIndex={5}
				>
					<AnimatePresence mode="wait">
						{active && (
							<AnimatedText
								key={item.slogan}
								text={item.slogan}
								fontColor={item.fontColor}
							></AnimatedText>
						)}
					</AnimatePresence>
				</Flex>
				<Flex borderRadius="5px" overflow="hidden">
					<AspectRatio
						w={{
							base: '70vw',
							sm: '50vw',
							md: '40vw',
							lg: '35vw',
							xl: '22vw',
						}}
						ratio={3 / 4}
						overflow="hidden"
					>
						<Box
							justifyContent={'center'}
							alignItems="center"
							overflow="hidden"
						>
							<Box
								position="absolute"
								w="100%"
								h="100%"
								zIndex={5}
								bgImage={blackDot}
								bgRepeat="repeat"
								top={0}
								bgSize={active ? '3px' : '4px'}
								opacity={0.2}
								mixBlendMode="overlay"
							></Box>

							<Box
								w="100%"
								h="100%"
								bg={item.color}
								position="absolute"
								top={0}
								left={0}
								opacity={0.5}
							></Box>
							{item?.topImage && (
								<Image
									src={item.topImage}
									objectFit="cover"
									w="100%"
									h="100%"
									zIndex={10}
									filter={active ? '' : 'blur(5px) grayscale(0.8)'}
									transition={
										'filter 0.5s ease-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out'
									}
									transform={active ? 'scale(1)' : 'scale(1.1)'}
									_hover={{ opacity: 0.5 }}
								/>
							)}

							<Flex position="absolute" top={0} zIndex={20} w="100%">
								{item?.title && active && (
									<motion.div
										animate={{ opacity: 1, y: 0 }}
										initial={{ opacity: 0, y: -30 }}
										exit={{ opacity: 0, y: -30 }}
										transition={{ duration: 1, ease: 'anticipate', delay: 1 }}
										style={{ width: '100%' }}
									>
										<Heading
											size={{ base: 'xs' }}
											textAlign="center"
											color="white"
											w="100%"
											py={{ base: 7, md: 25, lg: 35 }}
											bgGradient="linear(to-b, blackAlpha.500, transparent)"
										>
											{item.title}
										</Heading>
									</motion.div>
								)}
							</Flex>
							{item?.backImage && (
								<Image
									zIndex={1}
									src={item.backImage}
									objectFit="cover"
									w="100%"
									h="100%"
									position="absolute"
									filter={active ? '' : 'blur(5px) grayscale(0.8)'}
									transition={
										'filter 0.5s ease-out, transform 0.3s ease-in-out'
									}
									transform={active ? 'scale(1)' : 'scale(1.1)'}
								/>
							)}

							<Flex
								position="absolute"
								bottom={0}
								zIndex={20}
								alignItems="center"
								justifyContent="center"
								w="100%"
							>
								{active && item.title && (
									<motion.div
										animate={{ opacity: 1, y: 0 }}
										initial={{ opacity: 0, y: 30 }}
										exit={{ opacity: 0, y: 30 }}
										transition={{ duration: 1, ease: 'anticipate', delay: 1 }}
										style={{ width: '100%' }}
									>
										<Heading
											size={{ base: 'xs' }}
											textAlign="center"
											color="white"
											w="100%"
											py={{ base: 7, md: 25, lg: 35 }}
											bgGradient="linear(to-t, blackAlpha.500, transparent)"
										>
											{'view project'}
										</Heading>
									</motion.div>
								)}
							</Flex>
						</Box>
					</AspectRatio>
				</Flex>
			</Flex>
		</motion.div>
	);
};

export default GalleryItem;
