import { Box, Flex, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useContentful } from '../../hooks/useContentful';
import Gallery from '../../shared/components/Gallery';
import { ProjectEntry } from '../../types/portfolio';

import { Route, Routes, useParams } from 'react-router-dom';
import Footer from '../../shared/components/Footer';
import GalleryItem from '../../shared/components/GalleryItem';
import { projectToGalleryItem } from '../../shared/helper/gallery';
import me from '../../shared/img/me.png';
import me_t from '../../shared/img/me_transparent.png';
import { IGalleryItem } from '../../types/gallery';

import Project from '../project/Project';
import { motion } from 'framer-motion';

interface IQuery {
	name?: string;
	category?: string;
	limit?: 20;
	offset?: number;
}

const homeItem: IGalleryItem = {
	id: 'home',
	backImage: me,
	topImage: me_t,
	color: '#dbe8f5',
	slogan: 'design inter action',
};

const Home: React.FC = () => {
	const [openProject, setOpenProject] = useState<IGalleryItem | undefined>();

	const { id } = useParams();

	const [items, setItems] = useState<IGalleryItem[]>([homeItem]);

	const { get } = useContentful();

	const loadData = async () => {
		const { data, loaded, error } = await get<ProjectEntry[]>({
			content_type: 'project',
			order: '-fields.semester',
		});

		let items: IGalleryItem[] = [homeItem];

		data.map((project) => {
			items.push(projectToGalleryItem(project));
		});
		setItems(items);
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		let openProj = items.find((item) => item.id == id);
		setOpenProject(openProj);
	}, [items]);

	const placeHolderItems: IGalleryItem[] = [
		{
			id: 'no id',
			backImage: me,
			topImage: me_t,
			color: '#dbe8f5',
			title: 'Waschraum',
			slogan: 'Design Inter Action',
			button: { link: '/portfolio', text: 'view project' },
		},
	];

	return (
		<Flex h="85svh" flexDirection="column">
			<Gallery items={items.length > 0 ? items : placeHolderItems}></Gallery>

			<Footer></Footer>
		</Flex>
	);
};

export default Home;
