import {
	Container,
	Image,
	Button,
	Box,
	Text,
	Flex,
	IconButton,
	Heading,
	AspectRatio,
} from '@chakra-ui/react';
import { AnimatePresence, motion, MotionConfig, Variants } from 'framer-motion';
import { useState } from 'react';

import blackDot from '../../shared/img/black_dot.png';

import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';

interface Props {
	images: any[];
}

const swipeConfidenceThreshold = 0;
const swipePower = (offset: number, velocity: number) => {
	return Math.abs(offset) * velocity;
};

const Slideshow = (props: Props) => {
	const [[page, direction], setPage] = useState([0, 0]);

	const { images } = props;

	const variants = {
		enter: (direction: number) => {
			return {
				x: direction > 0 ? '100%' : '-100%',
				opacity: 0,
			};
		},
		center: {
			x: 0,
			opacity: 1,
		},
		exit: (direction: number) => {
			return {
				x: direction < 0 ? '100%' : '-100%',
				opacity: 0,
			};
		},
	};

	const fadeVariants = {
		init: {
			opacity: 0,
		},
		enter: {
			opacity: 1,
		},
	};

	const paginate = (newDirection: number) => {
		let newPage = page + newDirection;

		newPage =
			newPage < 0
				? images.length - 1
				: newPage > images.length - 1
				? 0
				: newPage;

		setPage([newPage, newDirection]);
	};

	return (
		<Box
			w="100%"
			py={{ base: 0, md: 20 }}
			bg="blackAlpha.100"
			position="relative"
			overflow="hidden"
		>
			<Box
				position="absolute"
				w="100%"
				h="100%"
				bgImage={blackDot}
				bgRepeat="repeat"
				top={0}
				bgSize={'3px'}
				opacity={0.1}
				mixBlendMode="overlay"
			></Box>
			<Flex opacity={0.2}>
				<AnimatePresence>
					<motion.div
						key={`bg ${images[page].fields.title}`}
						variants={fadeVariants}
						initial="init"
						animate="enter"
						exit="enter"
						transition={{
							duration: 2,
						}}
						style={{
							width: '100%',
							height: '100%',
							position: 'absolute',
							top: 0,
							left: 0,
						}}
					>
						<Image
							h="100%"
							w="100%"
							src={images[page].fields.file.url}
							alt={`${images[page].fields.title}`}
							objectFit="cover"
							filter="blur(20px)"
							transform="scale(1.1)"
							transformOrigin="center center"
						/>
					</motion.div>
				</AnimatePresence>
			</Flex>

			<Container
				maxW="4xl"
				py={5}
				alignContent="center"
				justifyContent="center"
				alignItems="center"
				display="flex"
				flexDirection="column"
			>
				<Flex
					w={{ base: '100%', lg: '80%' }}
					h={{ base: '50vh' }}
					minH={'400px'}
					position="relative"
					justifyContent={'center'}
					alignItems="center"
					overflow={'hidden'}
					borderRadius="5px"
				>
					{images && (
						<AnimatePresence custom={direction}>
							<motion.div
								key={`${images[page].fields.title}`}
								custom={direction}
								variants={variants}
								initial="enter"
								animate="center"
								exit="exit"
								transition={{
									ease: 'easeInOut',
									duration: 1,
								}}
								style={{ width: '100%', height: '100%', position: 'absolute' }}
							>
								<Flex
									width="100%"
									height="100%"
									justifyContent={'center'}
									alignItems="center"
								>
									{images[page].fields.description &&
										images[page].fields.description.length > 0 && (
											<Text
												position="absolute"
												bottom={0}
												width="100%"
												py={10}
												px={10}
												bg="blackAlpha.500"
												color="white"
											>
												{images[page].fields.description}
											</Text>
										)}
									<Image
										height="100%"
										src={images[page].fields.file.url}
										alt={`${images[page].fields.title}`}
										objectFit="contain"
									/>
								</Flex>
							</motion.div>
						</AnimatePresence>
					)}
				</Flex>
				{images.length > 1 && (
					<Flex flexDirection="column" alignItems="center" gap={5} py={5}>
						<Flex justifyContent="center">
							<IconButton
								size="lg"
								aria-label="Previous image"
								icon={<ChevronLeftIcon />}
								onClick={() => paginate(-1)}
								variant="ghost"
								isDisabled={page == 0}
							/>
							<IconButton
								size="lg"
								aria-label="Previous image"
								icon={<ChevronRightIcon />}
								onClick={() => paginate(1)}
								variant="ghost"
								isDisabled={page == images.length - 1}
							/>
						</Flex>
					</Flex>
				)}
			</Container>
		</Box>
	);
};

export default Slideshow;
