import { Container, Flex, Image, Button, Heading, Box } from '@chakra-ui/react';
import { motion, MotionConfig } from 'framer-motion';
import { useEffect, useState } from 'react';

import { Link, Route, Routes, useLocation } from 'react-router-dom';

import Logo from '../img/logo.svg';

export const NavBar = () => {
	const location = useLocation();

	const [scrollDown, setScrollDown] = useState<boolean>(true);

	useEffect(() => {
		var prevScrollpos = window.pageYOffset;

		window.onscroll = (event) => {
			var currentScrollPos = window.pageYOffset;

			if (prevScrollpos > currentScrollPos) {
				setScrollDown(false);
			} else {
				setScrollDown(true);
			}
			prevScrollpos = currentScrollPos;
		};
	}, []);

	useEffect(() => {}, [scrollDown]);

	return (
		<Flex
			position="sticky"
			transition="top 0.3s ease-in-out, height 1s ease-in-out"
			h={{ base: '15svh' }}
			w="100%"
			overflowX="hidden"
			alignItems="center"
			justifyContent="center"
			zIndex={100}
			top={scrollDown ? '-25vh' : 0}
			bg={location.pathname == '/' ? '' : scrollDown ? '' : 'white'}
			mixBlendMode="multiply"
			opacity={0.8}
		>
			<Container maxW="4xl">
				<Flex position="relative" justifyContent="center" alignItems="center">
					<Flex justifyContent="center" alignItems="center">
						{location.pathname != '/' && (
							<Box position="absolute" left={0}>
								<motion.div
									animate={{ opacity: 1, y: 0 }}
									initial={{ opacity: 0, y: -30 }}
									exit={{ opacity: 0, y: -30 }}
									transition={{ duration: 1, ease: 'anticipate', delay: 4 }}
								>
									<Link to="/">
										<Button variant="ghost" size="sm">
											{`Back`}
										</Button>
									</Link>
								</motion.div>
							</Box>
						)}

						{/* <Link to="/">
						<Image
							src={Logo}
							alt="Matthias Dietrich Logo"
							marginLeft={'10px'}
						/>
					</Link> */}
						<Flex>
							<motion.div
								animate={{ opacity: 1 }}
								initial={{ opacity: 0 }}
								transition={{ delay: 2 }}
							>
								<Link to="/">
									<Heading size="xs" textAlign="center">
										Matthias <br />
										Dietrich
									</Heading>
								</Link>
							</motion.div>
						</Flex>
					</Flex>
				</Flex>
			</Container>
		</Flex>
	);
};
