import { Button, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<Flex
			w="100vw"
			alignItems="center"
			justifyContent="center"
			h={{ base: '10vh' }}
			color="blackAlpha.600"
			position="absolute"
			bottom={0}
		>
			<motion.div
				animate={{ opacity: 1, y: 0 }}
				initial={{ opacity: 0, y: -30 }}
				exit={{ opacity: 0, y: -30 }}
				transition={{ duration: 1, ease: 'anticipate', delay: 2 }}
			>
				<Link to="/impressum">
					<Button variant="ghost" size={{ base: 'xs' }}>
						Impressum
					</Button>
				</Link>
			</motion.div>
		</Flex>
	);
};

export default Footer;
